import cn from 'classnames'

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  className?: string
  children: React.ReactNode
  subtle?: boolean
  textColor?: 'white' | 'blue' | 'gray' | 'red' | 'black'
  textPosition?: 'default' | 'none'
}

export const H1 = ({ className, children, ...props }: Props): JSX.Element => (
  <h1
    {...props}
    className={
      'text-gray font-medium text-5xl' + (className ? ` ${className}` : '')
    }
  >
    {children}
  </h1>
)

export const H2 = ({ className, children, ...props }: Props): JSX.Element => (
  <h2
    {...props}
    className={
      'text-gray font-medium text-2xl sm:text-3xl' +
      (className ? ` ${className}` : '')
    }
  >
    {children}
  </h2>
)

export const SubHeadline = ({
  className,
  children,
  textColor = 'gray',
  ...props
}: Props): JSX.Element => (
  <p
    {...props}
    className={cn(
      'text-left sm:text-center uppercase text-sm sm:text-base tracking-wider font-medium ' +
        (className ? ` ${className}` : ''),
      {
        'text-blue': textColor === 'blue',
        'text-red': textColor === 'red',
        'text-white': textColor === 'white',
        'text-gray text-opacity-50': textColor === 'gray',
        'text-black': textColor === 'black',
      },
    )}
  >
    {children}
  </p>
)

export const SmallItem = ({
  className,
  children,
  ...props
}: Props): JSX.Element => (
  <p
    {...props}
    className={
      'text-gray text-opacity-60 font-medium' +
      (className ? ` ${className}` : '')
    }
    style={{
      fontSize: '13px',
    }}
  >
    {children}
  </p>
)

export const Item = ({
  className,
  children,
  subtle,
  ...props
}: Props): JSX.Element => (
  <p
    {...props}
    className={
      'text-gray font-medium text-lg' +
      (className ? ` ${className}` : '') +
      (subtle ? ' text-opacity-60' : '')
    }
  >
    {children}
  </p>
)

export const Onboarding = {
  H1({ className, children, ...props }: Props): JSX.Element {
    return (
      <h1
        {...props}
        className={
          'text-left sm:text-center text-3xl sm:text-5xl spacer-xs text-gray sm:leading-snug font-medium ' +
          (className ? ` ${className}` : '')
        }
      >
        {children}
      </h1>
    )
  },
  H2({ className, children, ...props }: Props): JSX.Element {
    return (
      <h2
        {...props}
        className={
          'text-2xl sm:text-3xl text-blue leading-none mb-8 font-medium ' +
          (className ? ` ${className}` : '')
        }
      >
        {children}
      </h2>
    )
  },
}

export const Blog = {
  H1({ className, children, ...props }: Props): JSX.Element {
    return (
      <h1 {...props} className={`spacer-sm ${className || ''}`}>
        {children}
      </h1>
    )
  },
}

export const Marketing = {
  H1: ({
    className,
    textColor = 'gray',
    children,
    textPosition = 'default',
    ...props
  }: Props): JSX.Element => (
    <h1
      {...props}
      className={cn('break-words xs:break-normal', className || '', {
        'text-blue': textColor === 'blue',
        'text-red': textColor === 'red',
        'text-white': textColor === 'white',
        'text-neutral': textColor === 'gray',
        'text-left sm:text-center': textPosition === 'default',
      })}
    >
      {children}
    </h1>
  ),
  H2: ({ className, children, ...props }: Props): JSX.Element => (
    <h2
      {...props}
      className={`text-left sm:text-center
        ${className || ''}`}
    >
      {children}
    </h2>
  ),
  H3: ({ className, children, ...props }: Props): JSX.Element => (
    <h3 {...props} className={`${className || ''}`}>
      {children}
    </h3>
  ),
  H4: ({ className, children, ...props }: Props): JSX.Element => (
    <h4 {...props} className={`${className || ''}`}>
      {children}
    </h4>
  ),
  H5: ({ className, children, ...props }: Props): JSX.Element => (
    <h5 {...props} className={`${className || ''}`}>
      {children}
    </h5>
  ),
  H6: ({ className, children, ...props }: Props): JSX.Element => (
    <h6 {...props} className={`${className || ''}`}>
      {children}
    </h6>
  ),
  SubHeadline: ({
    className,
    textColor = 'gray',
    children,
    textPosition = 'default',
    ...props
  }: Props): JSX.Element => (
    <p
      {...props}
      className={cn(`mx-auto large-body-text ${className || ''}`, {
        'text-blue': textColor === 'blue',
        'text-red': textColor === 'red',
        'text-white text-opacity-90': textColor === 'white',
        'text-neutral-80': textColor === 'gray',
        'text-left sm:text-center': textPosition === 'default',
      })}
    >
      {children}
    </p>
  ),
}

export const Label = ({
  className,
  children,
  ...props
}: Props): JSX.Element => (
  <div {...props} className={`label ${className || ''}`}>
    {children}
  </div>
)
