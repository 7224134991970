import { useCallback, useEffect, useRef, useState } from 'react'

export interface TooltipProps extends React.HTMLAttributes<HTMLButtonElement> {
  title?: string
  description: string
  parentBgColor: 'white' | 'neutral' | 'blue' | 'neutral-on-dark'
  anchor?: 'left' | 'right'
  iconSize?: number
}

const Tooltip = ({
  title,
  description,
  parentBgColor = 'white',
  anchor = 'right',
  iconSize = 24,
  className,
  ...props
}: TooltipProps): JSX.Element => {
  const [visible, setVisible] = useState(false)
  const [isTouchscreen, setIsTouchscreen] = useState(false)
  const tooltipButtonRef = useRef<HTMLButtonElement>(null)

  const handleClick = (): void => {
    setVisible(!visible)

    if (isTouchscreen) {
      window.addEventListener('click', handleClickOutside)
    }
  }

  const handleClickOutside = useCallback((e): void => {
    if (e.target !== tooltipButtonRef.current) {
      setVisible(false)
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setIsTouchscreen('ontouchstart' in window || navigator.maxTouchPoints > 0)

    return () => window.removeEventListener('click', handleClickOutside)
  }, [handleClickOutside])

  return (
    <button
      className={`relative min-clickable-area flex-shrink cursor-default ${
        className || ''
      }`}
      onMouseEnter={() => !isTouchscreen && setVisible(true)}
      onMouseLeave={() => !isTouchscreen && setVisible(false)}
      onClick={handleClick}
      ref={tooltipButtonRef}
      {...props}
    >
      {/* Interrogation point + background */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        width={iconSize}
        height={iconSize}
        viewBox='0 0 24 24'
      >
        <circle
          cx='12'
          cy='12'
          r='12'
          className={`fill-current
            ${
              parentBgColor === 'neutral'
                ? 'text-neutral-on-dark-40'
                : parentBgColor === 'neutral-on-dark'
                  ? 'text-neutral-on-dark-25'
                  : parentBgColor === 'blue'
                    ? 'text-neutral-0 text-opacity-50'
                    : 'text-neutral-40'
            }`}
        />
        <path
          className={` fill-current
          ${
            parentBgColor === 'neutral'
              ? 'text-neutral'
              : parentBgColor === 'neutral-on-dark'
                ? 'text-neutral-0'
                : parentBgColor === 'blue'
                  ? 'text-blue'
                  : 'text-neutral-0'
          }`}
          d='M11.092 14.475c0-.49.063-.907.188-1.253a3.34 3.34 0 0 1 .46-.85c.183-.22.418-.465.706-.734.317-.307.547-.566.691-.777.144-.221.216-.49.216-.807 0-.422-.124-.753-.374-.993-.24-.25-.571-.375-.994-.375-.45 0-.816.159-1.094.476-.278.316-.418.715-.418 1.195v.13H8.832v-.13c0-.586.134-1.123.403-1.613s.638-.878 1.109-1.166c.48-.288 1.027-.432 1.641-.432.605 0 1.138.12 1.599.36.46.24.816.57 1.065.993.25.423.375.903.375 1.44 0 .547-.106.989-.317 1.325-.202.326-.504.677-.907 1.051-.375.355-.658.687-.85.994-.192.307-.288.696-.288 1.166v.144h-1.57v-.144zm.792 3.096a.977.977 0 0 1-.72-.288 1.008 1.008 0 0 1-.287-.72.96.96 0 0 1 .287-.705.977.977 0 0 1 .72-.288.93.93 0 0 1 .706.288.96.96 0 0 1 .288.705c0 .279-.096.519-.288.72a.96.96 0 0 1-.706.288z'
        />
      </svg>

      {/* Overlay */}
      {visible && (
        <div
          className={`fixed z-40 pointer-events-none w-200vw h-200vh left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-neutral-15`}
        />
      )}

      {/* Tooltip */}
      <div
        className={`absolute pointer-events-none z-50 ${
          visible ? 'opacity-100' : 'opacity-0'
        } transition-opacity text-left bg-neutral-0 rounded-8 shadow-big p-4 w-48 bottom-9 xs:w-60 sm:p-5 sm:w-80 ${
          anchor === 'right'
            ? '-right-8 md:-right-10'
            : anchor === 'left'
              ? '-left-8 md:-left-10'
              : ''
        }`}
      >
        {title && <p className='font-demibold text-neutral mb-1'>{title}</p>}

        <p className='small-body-text text-neutral-60'>{description}</p>

        {/* Arrow down */}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className={`block absolute -bottom-2 ${
            anchor === 'right'
              ? 'right-8 md:right-10'
              : anchor === 'left'
                ? 'left-8 md:left-10'
                : ''
          }`}
          fill='none'
          width={24}
          height={12}
          viewBox='0 0 24 12'
        >
          <path
            fill='#fff'
            d='M0 2a2 2 0 0 1 2-2h20a2 2 0 1 1 0 4h-2.8a2.4 2.4 0 0 0-1.92.96l-4.48 5.973a1 1 0 0 1-1.6 0L6.72 4.96A2.4 2.4 0 0 0 4.8 4H2a2 2 0 0 1-2-2z'
          />
        </svg>
      </div>
    </button>
  )
}

export default Tooltip
