const Thunder = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    width={32}
    height={33}
    viewBox='0 0 32 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M17.176 12.753l2.268-10.49a.625.625 0 00-1.118-.5L5.828 19.26a.625.625 0 00.508.992h9.157l1.683-7.499z'
      fill='currentColor'
    />
    <path
      opacity={0.4}
      d='M15.494 20.252l-2.268 10.486a.625.625 0 001.118.5L26.843 13.74a.624.624 0 00-.51-.988h-9.156l-1.683 7.5z'
      fill='currentColor'
    />
  </svg>
)

export default Thunder
