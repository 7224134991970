import React from 'react'
import { H2 } from '../designsystem/Text'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  noPadding?: boolean
  noMaxWidth?: boolean
  noBgColor?: boolean
  noShadow?: boolean
  hoverable?: boolean
}

export const Card = ({
  className,
  noPadding = false,
  noMaxWidth = false,
  noBgColor = false,
  noShadow = false,
  hoverable = false,
  ...props
}: Props): JSX.Element => (
  <div
    className={`rounded-12 ${className || ''} ${noPadding ? '' : 'py-6'} ${
      noShadow ? '' : 'shadow-small'
    } ${noMaxWidth ? '' : 'max-w-2xl'} ${noBgColor ? '' : 'bg-white'} ${
      hoverable ? 'hover-translate-up' : ''
    }`}
    {...props}
  />
)

// TODO: find out how to properly set sub-components display-name
// eslint-disable-next-line react/display-name
Card.Body = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div {...props} className={`px-4 sm:px-6 ${className || ''}`} />
)

// eslint-disable-next-line react/display-name
Card.Title = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>): JSX.Element => (
  <div className={`px-4 pb-5 sm:px-6 ${className || ''}`}>
    <H2 {...props}>{children}</H2>
  </div>
)
