const Stability = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    width={32}
    height={33}
    viewBox='0 0 32 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      opacity={0.4}
      d='M9.73 2.5a1.556 1.556 0 11.095 3.11l-3.927.12a.72.72 0 00-.668.668l-.12 3.927A1.556 1.556 0 112 10.23l.121-3.955.002-.044a3.833 3.833 0 013.653-3.61l3.954-.12zM26.89 22.675a1.556 1.556 0 113.11.094l-.121 3.956-.002.044a3.833 3.833 0 01-3.652 3.61l-3.955.12a1.556 1.556 0 11-.095-3.11l3.927-.119a.72.72 0 00.668-.668l.12-3.927zM22.27 2.5a1.556 1.556 0 10-.095 3.11l3.927.12c.362.029.639.306.668.668l.12 3.927A1.556 1.556 0 0030 10.23l-.121-3.955-.002-.044a3.832 3.832 0 00-3.652-3.61l-3.955-.12zM5.11 22.675a1.556 1.556 0 10-3.11.094l.121 3.956.002.044a3.833 3.833 0 003.653 3.61l3.955.12a1.556 1.556 0 10.094-3.11l-3.927-.119a.72.72 0 01-.668-.668l-.12-3.927z'
      fill='currentColor'
    />
    <path
      d='M22.242 11.019c-.915-1.465-3.09-1.332-3.82.233l-2.597 5.565-2.607-4.518c-.835-1.45-2.925-1.454-3.767-.01l-1.368 2.345H4.026c-.466 0-.98.218-1.184.73-.116.291-.22.68-.22 1.136 0 .456.104.845.22 1.137.203.511.718.73 1.184.73H8.62a2.8 2.8 0 002.418-1.39l.287-.49 2.76 4.784c.88 1.526 3.114 1.43 3.86-.167l2.649-5.678 1.016 1.625a2.8 2.8 0 002.374 1.316h4.033c.461 0 .978-.217 1.168-.738.104-.287.193-.667.193-1.129 0-.462-.09-.842-.193-1.129-.19-.52-.707-.737-1.168-.737H24.5l-2.259-3.615z'
      fill='currentColor'
    />
  </svg>
)

export default Stability
