import { Quote } from 'types/generated/contentful'
import Image from 'next/image'
import cn from 'classnames'
import Markdown from 'markdown-to-jsx'

interface CaseStudyQuoteProps extends React.HTMLAttributes<HTMLDivElement> {
  quote: Quote
  withImage?: boolean
  type?: 'card' | 'case-study-hero' | 'default'
}

const CaseStudyQuote = ({
  quote,
  withImage,
  type = 'default',
  className,
  ...props
}: CaseStudyQuoteProps): JSX.Element | null => {
  const { text, author } = quote

  return text ? (
    <div
      className={`${
        type === 'card'
          ? 'max-w-2xl mx-auto rounded-12 shadow-small bg-white p-6 sm:p-8'
          : ''
      } ${className || ''}`}
      {...props}
    >
      <Markdown
        options={{ forceWrapper: true, wrapper: 'blockquote' }}
        className={cn('quotation-marks', {
          'large-body-text': type === 'card',
          'large-body-text opacity-60': type === 'case-study-hero',
        })}
      >
        {text}
      </Markdown>

      <div className='spacer-3xs flex items-center space-x-3 sm:space-x-4'>
        {withImage && author?.avatar?.url && (
          <div className='relative flex-shrink-0 h-8 w-8 sm:h-10 sm:w-10 lg:h-12 lg:w-12'>
            <Image
              className='rounded-full'
              alt={`Picture of ${author.name}`}
              src={author.avatar.url}
              fill
              style={{ objectFit: 'cover' }}
            />
          </div>
        )}

        {author && (
          <div
            className={cn('small-body-text font-demibold', {
              'md:flex md:space-x-2': type === 'case-study-hero',
            })}
          >
            <p>{author.name}</p>

            <p className='opacity-50'>
              {author.role}
              {author.company?.name && `, ${author.company.name}`}
            </p>
          </div>
        )}
      </div>
    </div>
  ) : null
}

export default CaseStudyQuote
