const Shield = ({ ...props }: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    width={32}
    height={33}
    viewBox='0 0 32 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M28.654 14.676a1.6 1.6 0 01-1.6 1.6H15.976V5.242a1.6 1.6 0 011.6-1.6h8.981c1.145 0 2.085.93 2.096 2.085 0 5.743.002 6.796.001 8.95z'
      fill='currentColor'
    />
    <path
      opacity={0.4}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.97 6.702v9.593H6.217a12.326 12.326 0 01-.184-2.042l.008.008.016-5.972c0-.87.708-1.587 1.587-1.587H15.97z'
      fill='currentColor'
    />
    <path
      d='M15.976 16.276H5.349c-.995 0-1.753.903-1.462 1.855 1.487 4.866 5.269 8.828 10.173 10.561.965.342 1.916-.42 1.916-1.443V16.276z'
      fill='currentColor'
    />
    <path
      opacity={0.4}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.024 16.295H15.97v10.547a.404.404 0 00.04-.01v-.008a13.215 13.215 0 0010.014-10.53z'
      fill='currentColor'
    />
  </svg>
)

export default Shield
