const LinkArrow = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 10 16'
    width={10}
    height={16}
    {...props}
  >
    <path d='m1 15 7-7-7-7' fill='none' strokeWidth='2.5' />
  </svg>
)

export default LinkArrow
