interface Props extends React.SVGAttributes<SVGElement> {
  context: string
}

const RateLimitingIcon = ({ context, ...props }: Props): JSX.Element => (
  <svg
    width='43'
    height='44'
    viewBox='0 0 43 44'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <mask
      id={`rate-limiting-mask-${context}`}
      style={{ maskType: 'alpha' }}
      maskUnits='userSpaceOnUse'
      x='3'
      y='0'
      width='36'
      height='45'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.7657 9.33977V19.0949C38.7657 20.2819 38.7657 22.7116 37.8286 25.9254C36.8851 29.6097 35.1346 32.6735 33.1058 35.1743C33.0349 35.27 32.963 35.3659 32.8903 35.462C32.8903 35.462 32.8964 35.4462 32.9075 35.4159C29.0907 40.0125 24.3657 42.6568 22.1653 43.7216C21.3797 44.1018 20.4827 44.0922 19.7046 43.6968C15.6759 41.6494 3.09375 34.042 3.09375 18.8853V9.59644C3.09375 7.93227 4.29836 6.51622 5.94035 6.24538L16.9461 4.43L17.992 0L36.47 6.15931C37.8409 6.6163 38.7657 7.89467 38.7657 9.33977Z'
        fill='#608BD8'
      />
    </mask>
    <g mask={`url(#rate-limiting-mask-${context}`}>
      <path
        d='M3.4375 10.6529C3.4375 8.56727 5.3084 6.36456 7.36621 6.02513L22.5701 3.51727C23.2813 3.39995 24.0114 3.51317 24.6537 3.84038L33.9944 8.59884C34.4695 8.84084 34.9626 9.12754 35.1382 9.63093C35.5015 10.6728 35.9683 13.3498 35.9683 20.2276C35.9683 22.4844 38.6134 23.1072 38.1331 25.0239C35.6817 35.9334 25.8982 41.6872 22.4078 43.4245C21.6178 43.8177 20.7101 43.8148 19.9238 43.4144C15.8972 41.3638 3.4375 33.8038 3.4375 18.7679C3.4375 15.0231 3.4375 12.4377 3.4375 10.6529Z'
        fill='#CC5A3B'
      />
      <g filter={`url(#rate-limiting-filter-${context}`}>
        <path
          d='M38.8873 18.9763V9.28174C38.8873 7.84561 37.9683 6.57518 36.6059 6.12104L18.2427 0L14.7238 15.6919C14.7238 15.6919 24.9937 21.0517 31.1716 24.3981C37.3496 27.7445 33.1201 35.0331 33.1201 35.0331C38.6266 28.6078 38.8873 21.1514 38.8873 18.9763Z'
          fill='#FF7752'
        />
      </g>
    </g>
    <defs>
      <filter
        id={`rate-limiting-filter-${context}`}
        x='9.40969'
        y='-4.68891'
        width='34.7917'
        height='45.6614'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='0.625188' />
        <feGaussianBlur stdDeviation='2.65705' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_600_320'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_600_320'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

export default RateLimitingIcon
