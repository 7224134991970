import { type CaseStudy } from 'types/generated/contentful'
import IconRenderer, { Icon } from './IconRenderer'
import Tooltip, { type TooltipProps } from '../common/Tooltip'

interface ImprovementStatsProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: CaseStudy['improvementType']
  improvement?:
    | CaseStudy['increasedPerformance']
    | CaseStudy['costReduction']
    | CaseStudy['serverLoadReduction']
  isAverage?: boolean
  orientation?: 'horizontal' | 'vertical'
}

export const FasterResponseTimeTooltip = (
  props: Omit<TooltipProps, 'description'>,
) => (
  <Tooltip
    iconSize={18}
    {...props}
    description={
      "Our edge cache's p95 response time globally is 50ms. Because of their cache hit rate, their overall APIs p50 response time is this much faster."
    }
  />
)

export const CostReductionTooltip = (
  props: Omit<TooltipProps, 'description'>,
) => (
  <Tooltip
    iconSize={18}
    {...props}
    description={
      'Our edge cache serving a request from the cache is usually much cheaper than the origin infrastructure handling that request, so our customers with good cache utilization can see significant infrastructure cost reduction after integrating the Stellate Edge Cache.'
    }
  />
)

export const LessApiTrafficTooltip = (
  props: Omit<TooltipProps, 'description'>,
) => (
  <Tooltip
    iconSize={18}
    {...props}
    description={
      'Every request that is handled by our edge cache never touches their infrastructure. Thus their overall API traffic is reduced by this much (this corresponds to the "Cache hit rate" you can see in the Stellate dashboard).'
    }
  />
)

const ImprovementStats = ({
  type,
  improvement,
  isAverage,
  orientation = 'horizontal',
  className,
  ...props
}: ImprovementStatsProps): JSX.Element | null => {
  if (!type || !improvement) return null

  return (
    <div
      className={`flex flex-1 justify-between pl-6 pt-5 pr-8 pb-7 text-white rounded-8 ${
        type === 'increased-performance'
          ? 'bg-neutral'
          : type === 'server-load-reduction'
            ? 'bg-blue'
            : type === 'cost-reduction'
              ? 'bg-blue'
              : ''
      } ${orientation === 'vertical' ? 'md:flex-col' : ''} ${
        className ? className : ''
      }`}
      {...props}
    >
      <div>
        <p className='h2-size'>{improvement}%</p>

        <div className='flex items-center gap-1.5'>
          {type === 'increased-performance' ? (
            <>
              <p className='small-body-text'>
                {isAverage ? 'Av. ' : ''}Faster p50 API response time
              </p>
              <FasterResponseTimeTooltip parentBgColor='neutral-on-dark' />
            </>
          ) : type === 'cost-reduction' ? (
            <>
              <p className='small-body-text'>
                {isAverage ? 'Av. ' : ''}Reduction in Cloud Cost
              </p>
              <CostReductionTooltip parentBgColor='blue' />
            </>
          ) : (
            <>
              <p className='small-body-text'>
                {isAverage ? 'Av. ' : ''}Less API traffic
              </p>
              <LessApiTrafficTooltip parentBgColor='blue' />
            </>
          )}
        </div>
      </div>

      <IconRenderer
        icon={type as Icon}
        className={'h-12 text-white self-end'}
      />
    </div>
  )
}

export default ImprovementStats
