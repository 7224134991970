import { Card } from 'designsystem/Card'
import LinkArrowBig from '../../icons/LinkArrowBig'
import NextLink from 'next/link'
import ImprovementStats from './ImprovementStats'
import CaseStudyQuote from './CaseStudyQuote'
import { CASE_STUDY_SLUG } from 'common/constants'
import { CaseStudy, Quote } from 'types/generated/contentful'
import { useMemo } from 'react'

type Overides = {
  noShadow?: boolean
  hoverable?: boolean
  orientation?: 'horizontal' | 'vertical'
}

interface CaseStudyCardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Overides {
  caseStudy: CaseStudy
  cardStyleOverride?:
    | 'quote-only'
    | 'stats-only'
    | 'quote-stats'
    | 'quote-image'
    | 'quote-stats-image'
  anchorOnFocus?: () => void
}

const CaseStudyCard = ({
  caseStudy,
  cardStyleOverride,
  orientation,
  anchorOnFocus,
  className,
  ...props
}: CaseStudyCardProps): JSX.Element | null => {
  const { slug, cardStyle } = caseStudy

  const style = useMemo(
    () => cardStyleOverride || cardStyle || 'quote-only',
    [cardStyle, cardStyleOverride],
  )

  const renderSwitch = (cardStyle: string) => {
    switch (cardStyle) {
      case 'quote-only': {
        return (
          caseStudy.quote && (
            <CommonCard className={`p-8 ${className || ''}`} {...props}>
              <QuotePlusLinkSection quote={caseStudy.quote} />
            </CommonCard>
          )
        )
      }

      case 'stats-only': {
        return (
          <CommonCard
            className={`p-2 flex flex-col ${className || ''}`}
            {...props}
          >
            <ImprovementStats
              type={caseStudy.improvementType}
              improvement={
                caseStudy.improvementType === 'increased-performance'
                  ? caseStudy.increasedPerformance
                  : caseStudy.improvementType === 'cost-reduction'
                    ? caseStudy.costReduction
                    : caseStudy.serverLoadReduction
              }
              orientation={orientation || 'horizontal'}
              className='flex-grow'
            />

            {caseStudy.quote && (
              <LinkSection quote={caseStudy.quote} className='px-6 pb-6' />
            )}
          </CommonCard>
        )
      }

      case 'quote-stats': {
        return (
          <CommonCard
            className={`p-2 flex flex-col md:flex-row ${className || ''}`}
            {...props}
          >
            <ImprovementStats
              type={caseStudy.improvementType}
              improvement={
                caseStudy.improvementType === 'increased-performance'
                  ? caseStudy.increasedPerformance
                  : caseStudy.improvementType === 'cost-reduction'
                    ? caseStudy.costReduction
                    : caseStudy.serverLoadReduction
              }
              orientation={orientation || 'vertical'}
              className='flex-grow w-full md:min-w-45 md:max-w-60'
            />

            {caseStudy.quote && (
              <QuotePlusLinkSection
                className='p-6 md:pl-8'
                quote={caseStudy.quote}
              />
            )}
          </CommonCard>
        )
      }

      case 'quote-image': {
        return (
          <CommonCard className={`p-8 lg:pb-0 ${className || ''}`} {...props}>
            {caseStudy.quote && <CaseStudyQuote quote={caseStudy.quote} />}

            {caseStudy.quote && (
              <div className='lg:flex lg:items-end lg:justify-between lg:-mt-10'>
                <LinkSection
                  quote={caseStudy.quote}
                  className='lg:pb-8 lg:justify-items-start lg:space-x-4'
                />

                <AuthorImage caseStudy={caseStudy} className='lg:mr-20' />
              </div>
            )}
          </CommonCard>
        )
      }

      case 'quote-stats-image': {
        return (
          <CommonCard
            className={`flex flex-col md:flex-row ${className || ''}`}
            {...props}
          >
            <div className='p-2 flex-grow w-full md:min-w-45 md:max-w-60'>
              <ImprovementStats
                type={caseStudy.improvementType}
                improvement={
                  caseStudy.improvementType === 'increased-performance'
                    ? caseStudy.increasedPerformance
                    : caseStudy.improvementType === 'cost-reduction'
                      ? caseStudy.costReduction
                      : caseStudy.serverLoadReduction
                }
                orientation='vertical'
                className='h-full'
              />
            </div>

            <AuthorImage caseStudy={caseStudy} className='lg:ml-6 lg:pt-10' />

            {caseStudy.quote && (
              <QuotePlusLinkSection
                className='p-6 md:p-8'
                quote={caseStudy.quote}
              />
            )}
          </CommonCard>
        )
      }

      default: {
        return null
      }
    }
  }

  return (
    <NextLink
      href={`${CASE_STUDY_SLUG}/${slug}`}
      className='h-full block group'
      onFocus={anchorOnFocus}
    >
      {renderSwitch(style)}
    </NextLink>
  )
}

export default CaseStudyCard

export const CommonCard = ({
  noShadow,
  hoverable,
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & Overides): JSX.Element => {
  return (
    <Card
      noPadding
      noMaxWidth
      hoverable={hoverable !== undefined ? hoverable : true}
      noShadow={noShadow !== undefined ? noShadow : false}
      className={`h-full ${className || ''}`}
      {...props}
    >
      {children}
    </Card>
  )
}

const LinkSection = ({
  quote,
  className,
  ...props
}: QuotePlusLinkSectionProps): JSX.Element | null => {
  const { logo, name } = quote?.author?.company || {}
  const { url, width, height, title } = logo || {}

  return (
    <div
      className={`spacer-xs flex justify-between items-center ${
        className || ''
      }`}
      {...props}
    >
      {url && width && height && (
        <img
          src={url}
          width={width}
          height={height}
          alt={title || `${name} logo` || ''}
          className='h-8 w-auto'
        />
      )}

      <LinkArrowBig className='fill-current h-5 opacity-30 transition-opacity group-hover:opacity-70 group-focus:opacity-70' />
    </div>
  )
}

type QuotePlusLinkSectionProps = {
  quote?: Quote
  className?: string
  withImage?: boolean
}

export const QuotePlusLinkSection = ({
  quote,
  className,
  withImage,
  ...props
}: QuotePlusLinkSectionProps): JSX.Element | null => {
  return (
    <div
      className={`flex flex-col flex-1 justify-between h-full ${
        className || ''
      }`}
      {...props}
    >
      {quote && <CaseStudyQuote withImage={withImage} quote={quote} />}

      <LinkSection quote={quote} />
    </div>
  )
}

const AuthorImage = ({
  caseStudy,
  className,
  ...props
}: CaseStudyCardProps): JSX.Element | null => {
  const { quote } = caseStudy

  const { name } = quote?.author || {}
  const { url, width, height, title } = quote?.author?.caseStudyImage || {}

  return (
    <div
      className={`hidden lg:flex lg:items-end lg:w-full lg:max-w-60 ${
        className || ''
      }`}
      {...props}
    >
      {url && width && height && (
        <img
          src={url}
          width={width}
          height={height}
          alt={title || `Picture of ${name}` || ''}
          className='w-full'
        />
      )}
    </div>
  )
}
