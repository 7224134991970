const LinkArrowBig = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 15 24'
    {...props}
  >
    <path d='m12 12 1.414 1.414L14.828 12l-1.414-1.414L12 12Zm-1.414-1.414-10 10 2.828 2.828 10-10-2.828-2.828Zm-10-7.172 10 10 2.828-2.828-10-10L.586 3.414Z' />
  </svg>
)

export default LinkArrowBig
