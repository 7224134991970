interface Props extends React.SVGAttributes<SVGElement> {
  context: string
}

const MetricsIcon = ({ context, ...props }: Props): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 88 92' {...props}>
    <defs>
      <filter
        id={`metrics-filter-${context}`}
        width='126.866'
        height='159.364'
        x='.068'
        y='-24.293'
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='4.639' />
        <feGaussianBlur stdDeviation='19.716' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0' />
        <feBlend
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1559:23260'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_1559:23260'
          result='shape'
        />
      </filter>
    </defs>
    <path
      fill='#9B1D62'
      d='M87.5 42A50.64 50.64 0 0151 91l-9.44-41.06-37.8 3.78a3 3 0 01-3.27-2.54A52.34 52.34 0 010 44C0 20.11 18.73 1 42 1a41.9 41.9 0 0130.45 13.2L77 10.5c7 8.75 10.5 19.41 10.5 31.5z'
    />
    <mask
      id={`analytics-mask-${context}`}
      width='88'
      height='90'
      x='0'
      y='1'
      maskUnits='userSpaceOnUse'
      style={{ maskType: 'alpha' }}
    >
      <path
        fill='#9B1C62'
        d='M87.5 42A50.64 50.64 0 0151 91l-9.44-41.06-37.8 3.78a3 3 0 01-3.27-2.54A52.34 52.34 0 010 44C0 20.11 18.73 1 42 1a41.9 41.9 0 0130.45 13.2L77 10.5c7 8.75 10.5 19.41 10.5 31.5z'
      />
    </mask>
    <g
      filter={`url(#metrics-filter-${context}`}
      mask={`url(#analytics-mask-${context}`}
    >
      <path
        fill='#FF6B8B'
        d='M87.5 42A50.64 50.64 0 0151 91L39.5 41 77 10.5c7 8.75 10.5 19.41 10.5 31.5z'
      />
    </g>
  </svg>
)

export default MetricsIcon
