const Dollars = ({
  ...props
}: React.SVGAttributes<SVGElement>): JSX.Element => (
  <svg
    width={32}
    height={33}
    viewBox='0 0 32 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.01 19.891c0-6.666-9.561-4.603-9.561-8.623 0-1.402 1.07-2.46 3.03-2.46 1.796 0 2.96 1.095 3.201 2.693.032.21.202.375.411.375h2.245a.38.38 0 00.382-.418c-.268-2.531-1.885-4.552-4.433-5.197a.416.416 0 01-.317-.4V3.97c0-.221-.176-.4-.395-.4H5.542a.398.398 0 00-.395.4v1.82a.414.414 0 01-.337.404C2.014 6.743.418 8.746.418 11.214c0 6.587 9.562 4.656 9.562 8.756 0 1.35-1.071 2.434-3.266 2.434-2.102 0-3.335-1.158-3.635-2.867a.423.423 0 00-.407-.36H.412a.381.381 0 00-.382.417c.27 2.684 1.995 4.767 4.79 5.39a.414.414 0 01.327.401v1.857c0 .22.177.4.395.4h2.031a.397.397 0 00.395-.4v-1.788c0-.201.149-.37.345-.404 3.046-.523 4.698-2.583 4.698-5.159z'
      fill='currentColor'
    />
    <path
      opacity={0.4}
      d='M23.81 22.227c0-4.626-6.569-3.194-6.569-5.985 0-.973.736-1.707 2.082-1.707 1.24 0 2.042.768 2.202 1.886.02.136.13.244.266.244h1.57a.248.248 0 00.25-.272c-.178-1.77-1.296-3.183-3.06-3.629a.27.27 0 01-.204-.26V11.16a.258.258 0 00-.257-.26h-1.425a.258.258 0 00-.257.26v1.297a.268.268 0 01-.218.261c-1.93.378-3.03 1.77-3.03 3.488 0 4.571 6.568 3.23 6.568 6.077 0 .936-.735 1.689-2.243 1.689-1.45 0-2.299-.811-2.5-2.006a.274.274 0 00-.264-.234h-1.58a.249.249 0 00-.25.271c.18 1.876 1.371 3.332 3.306 3.762a.268.268 0 01.211.26v1.322c0 .143.115.26.257.26h1.425a.258.258 0 00.256-.26v-1.274c0-.13.097-.24.224-.262 2.1-.36 3.24-1.792 3.24-3.584zM31.867 23.724c0-3.277-4.548-2.263-4.548-4.24 0-.689.51-1.209 1.441-1.209.853 0 1.407.537 1.522 1.32.016.105.101.189.206.189h1.048a.19.19 0 00.191-.21c-.128-1.24-.894-2.23-2.102-2.55a.209.209 0 01-.156-.2V15.9c0-.11-.089-.2-.198-.2h-.947c-.109 0-.197.09-.197.2v.89a.208.208 0 01-.168.202c-1.325.272-2.081 1.255-2.081 2.466 0 3.239 4.547 2.29 4.547 4.305 0 .663-.509 1.196-1.553 1.196-.998 0-1.584-.567-1.728-1.406a.211.211 0 00-.203-.18h-1.056a.19.19 0 00-.191.208c.129 1.316.946 2.337 2.27 2.645a.208.208 0 01.163.2v.908c0 .11.088.2.197.2h.947c.11 0 .198-.09.198-.2v-.873c0-.1.073-.184.171-.202 1.444-.26 2.227-1.27 2.227-2.535z'
      fill='currentColor'
    />
  </svg>
)

export default Dollars
