interface Props extends React.SVGAttributes<SVGElement> {
  context: string
}

const EdgeCachingIcon = ({ context, ...props }: Props): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72 92' {...props}>
    <defs>
      <filter
        id={`edge-caching-filter-${context}`}
        width='147.865'
        height='164.004'
        x='-36.434'
        y='-34.793'
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dy='4.639' />
        <feGaussianBlur stdDeviation='19.716' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0' />
        <feBlend
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1559:23265'
        />
        <feBlend
          in='SourceGraphic'
          in2='effect1_dropShadow_1559:23265'
          result='shape'
        />
      </filter>
    </defs>
    <path
      fill='#4568A4'
      fillRule='evenodd'
      d='M69.488 51.45l-33.36 33.67-5.73 5.81a3.07 3.07 0 01-4 .35l-25.1-17.85a3.08 3.08 0 01-.51-4.57L23.118 44C5.058 22.45 2.998 20.5 2.998 20.5l29-20.5 37.6 39.36a8.67 8.67 0 01-.11 12.09z'
      clipRule='evenodd'
    />
    <mask
      id={`edge-caching-mask-${context}`}
      width='72'
      height='92'
      x='0'
      y='0'
      maskUnits='userSpaceOnUse'
      style={{ maskType: 'alpha' }}
    >
      <path
        fill='#959CA9'
        fillRule='evenodd'
        d='M69.488 51.45l-33.36 33.67-5.73 5.81a3.07 3.07 0 01-4 .35l-25.1-17.85a3.08 3.08 0 01-.51-4.57L23.118 44C5.058 22.45 2.998 20.5 2.998 20.5l29-20.5 37.6 39.36a8.67 8.67 0 01-.11 12.09z'
        clipRule='evenodd'
      />
    </mask>
    <g
      filter={`url(#edge-caching-filter-${context}`}
      mask={`url(#edge-caching-mask-${context}`}
    >
      <path
        fill='#608BD8'
        fillRule='evenodd'
        d='M2.998 20.5l29-20.5 37.6 39.36a8.67 8.67 0 01-.11 12.09l-33.37 33.69s10.26-13.14 1.05-24.23C6.048 23.38 2.998 20.5 2.998 20.5z'
        clipRule='evenodd'
      />
    </g>
  </svg>
)

export default EdgeCachingIcon
